import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, graphql } from '../helpers/fetch'
import { clearCart, setCartId, setCartItems, setCartToken } from './cart'
import { clearPriceRule } from './priceRule'
import { clearQuote, setQuoteId } from './quote'
import { getWishlistItems } from './wishlist'

export const getCustomerCartToken = createAsyncThunk(
  'auth/getCustomerCartToken',
  async (arg, { getState, dispatch }) => {
    const state = getState()
    if (!state.auth.token)
      return {
        token: null,
        user: null,
      }
    const { data: res, errors } = await graphql(
      `${process.env.GATSBY_CMS_URL}/graphql`,
      `
        {
          customerCart {
            id
            quote {
              cart_id
              cart_token
              quote_id
            }
            items {
              id
              product {
                name
                sku
              }
              quantity
            }
          }
          customer {
            customer_category_id
          }
        }
      `,
      state.auth.token
    )
    if (res.customerCart?.quote?.quote_id) {
      dispatch(setQuoteId(res.customerCart.quote.quote_id))
    }
    if (res.customerCart?.quote?.cart_id) {
      dispatch(setCartId(res.customerCart.quote?.cart_id))
    }
    if (res.customerCart?.quote?.cart_token) {
      dispatch(setCartToken(res.customerCart.quote?.cart_token))
    } else {
      dispatch(setCartToken(res.customerCart.id))
    }
    dispatch(setCustomerCategoryId(res.customer?.customer_category_id))
    return null
  }
)

export const getCustomer = createAsyncThunk(
  'auth/getCustomer',
  async (arg, { getState, dispatch }) => {
    const state = getState()
    if (!state.auth.token) return null
    const res = await get(
      `${process.env.GATSBY_CMS_URL}/rest/V1/customers/me`,
      state.auth.token
    ).catch(console.error)

    if (res && res.status === 200) {
      return res.json()
    } else {
      dispatch(clearCart())
      dispatch(setToken(null))
      dispatch(clearPriceRule())
      dispatch(setCustomerCategoryId(''))
    }
    return null
  }
)

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { dispatch }) => {
    dispatch(setErrors([]))
    const { data: res, errors } = await graphql(
      `${process.env.GATSBY_CMS_URL}/graphql`,
      `
        mutation {
          generateCustomerToken(
            email: "${email}"
            password: "${password}"
          ) {
            token
          }
        }
      `
    )

    if (!errors) {
      dispatch(setToken(res.generateCustomerToken.token))
      dispatch(getCustomer())
      dispatch(getWishlistItems())
    } else {
      dispatch(setErrors(errors.map(e => e.message)))
    }
  }
)

const initialState = {
  token: '',
  user: null,
  errors: [],
  loading: false,
  customerCategoryId: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    initializeAuthState: state => {
      state.errors = []
      state.loading = false
    },
    setCustomerCategoryId: (state, action) => {
      state.customerCategoryId = action.payload
    },
  },
  extraReducers: builder => {
    // builder.addCase(getCustomerId.fulfilled, (state, { payload }) => {
    //   if (state.user) {
    //     state.user.id = payload
    //   } else {
    //     state.user = {
    //       id: payload,
    //     }
    //   }
    // })
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      // Add user to the state array
      state.user = action.payload
    })
    builder.addCase(login.pending, state => {
      state.loading = true
    })
    builder.addCase(login.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(login.rejected, state => {
      state.loading = false
    })
  },
})

export const {
  setToken,
  setUser,
  setErrors,
  setLoading,
  initializeAuthState,
  setCustomerCategoryId,
} = authSlice.actions

export default authSlice.reducer
