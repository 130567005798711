import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, graphql, post } from '../helpers/fetch'
import Product from '../models/product'

const initialState = {
  items: {},
}

export const fetchPriceRuleItems = createAsyncThunk(
  'priceRule/fetchPriceRuleItems',
  async (arg, { getState }) => {
    const state = getState()
    if (!state.auth.token) return []

    const catalogPriceQuery = (page = 1) => `
    {
      products(search: "", pageSize: 300, currentPage: ${page} ) {
        items {
          id
          uid
          ... on SimpleProduct {
            price_range {
              minimum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
                discount {
                  amount_off
                }
              }
            }
          }
        }
        total_count
        page_info {
          total_pages
          current_page
          page_size
        }
      }
    }
    `

    const getByPage = async (page = 1, results = {}) => {
      const res = await graphql(
        `${process.env.GATSBY_CMS_URL}/graphql`,
        catalogPriceQuery(page),
        state.auth.token
      )
      if (!res?.data) return results

      const {
        products: { items, page_info },
      } = res.data

      for (const item of items) {
        if (!item?.price_range) continue
        const pItem = new Product(item)
        if (pItem.id == 3080) console.log(pItem)
        results[pItem.id] = {
          price: pItem.salePrice,
          discountOff: pItem.discountOff
        }
      }

      if (page_info && page_info.current_page < page_info.total_pages) {
        return await getByPage(page_info.current_page + 1, results)
      }

      return results
    }

    return await getByPage(1)
  }
)

export const priceRuleSlice = createSlice({
  name: 'priceRule',
  initialState,
  reducers: {
    clearPriceRule: state => {
      state.items = {}
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPriceRuleItems.fulfilled, (state, action) => {
      state.items = action.payload
    })
  },
})

export const { clearPriceRule } = priceRuleSlice.actions

export default priceRuleSlice.reducer
