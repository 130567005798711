import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, graphql, post } from '../helpers/fetch'

const initialState = {
  id: null,
  items: [],
}

export const getQuoteId = createAsyncThunk(
  'quote/getId',
  async (arg, { getState, dispatch }) => {
    const state = getState()
    if (!state.quote.token) return null
    const res = await get(
      `${process.env.GATSBY_CMS_URL}/rest/V1/guest-carts/${state.quote.token}`
    ).catch(console.error)

    if (res && res.status === 200) {
      const data = await res.json()
      return data.id
    }
    return null
  }
)

export const getQuoteItems = createAsyncThunk(
  'quote/getItems',
  async (arg, { getState, dispatch }) => {
    const state = getState()
    if (!state.quote.id)
      return {
        items: [],
      }
    const res = await get(
      `${process.env.GATSBY_CMS_URL}/rest/V1/carts/${state.quote.id}/items`,
      process.env.GATSBY_MANAGE_CART_TOKEN
    )
    if (res.status !== 200) {
      return {
        items: [],
      }
    }
    const items = await res.json()
    return {
      items,
    }
  }
)

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuoteId: (state, action) => {
      state.id = action.payload
    },
    setQuoteToken: (state, action) => {
      state.token = action.payload
    },
    setQuoteItems: (state, action) => {
      state.items = action.payload
    },
    clearQuote: (state, action) => {
      state.id = null
      state.token = null
      state.items = []
    },
  },
  extraReducers: builder => {
    builder.addCase(getQuoteId.fulfilled, (state, action) => {
      state.id = action.payload
    })
    builder.addCase(getQuoteItems.fulfilled, (state, action) => {
      state.items = action.payload.items
    })
  },
})

export const { setQuoteToken, setQuoteItems, setQuoteId, clearQuote } =
  quoteSlice.actions

export default quoteSlice.reducer
