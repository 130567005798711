const fetch = require('node-fetch')

const post = async (url, data, token = null) => {
  const headers = {
    'Content-Type': 'application/json'
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const response = await fetch(url, {
    method: 'POST',
    headers,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  })
  return response.json()
}

const get = async (url, token = null) => {
  const headers = {}

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return await fetch(url, {
    method: 'GET',
    headers,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  })
}

const graphql = async (url, query, token = null) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
    }),
  })

  return await res.json()
}

module.exports = {
  get,
  post,
  graphql,
}
