import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, graphql } from '../helpers/fetch'
import { setQuoteId, setQuoteItems, setQuoteToken } from './quote'
import { Provider, useDispatch, useSelector } from 'react-redux'

const createNewCart = async () => {
  const { data: res, errors } = await graphql(
    `${process.env.GATSBY_CMS_URL}/graphql`,
    `
      mutation {
        createEmptyCart
      }
    `
  )
  if (errors) {
    console.error(errors)
    return null
  }
  return res.createEmptyCart
}

export const createGuestCart = createAsyncThunk(
  'cart/guestCreate',
  createNewCart
)

export const getCartItems = createAsyncThunk(
  'cart/getItems',
  async (arg, { getState, dispatch }) => {
    const state = getState();
    if (!state.cart.token)
      return {
        items: [],
      }
    const { data: res, errors } = await graphql(
      `${process.env.GATSBY_CMS_URL}/graphql`,
      `
        {
          cart(cart_id: "${state.cart.token}") {
            email
            items {
              id
              product {
                name
                sku
              }
              quantity
            }
            prices {
              grand_total {
                value
                currency
              }
            }
          }
        }
      `,
      state.auth.token
    )
    if (res.cart?.quote?.quote_id) {
      dispatch(setQuoteId(res.cart.quote.quote_id))
    }
    if (res.cart?.quote?.cart_id) {
      dispatch(setCartId(res.cart.quote?.cart_id))
    }
    if (res.cart?.quote?.cart_token) {
      dispatch(setCartToken(res.cart.quote?.cart_token))
    }
    if(errors) {
        dispatch(createGuestCart());
    }
    return {
      items: (res?.cart?.items || []).filter(_ => !!_),
    }
  }
)

export const getCartId = createAsyncThunk(
  'cart/getId',
  async (arg, { getState, dispatch }) => {
    const state = getState()
    if (!state.cart.token) return null
    const res = await get(
      `${process.env.GATSBY_CMS_URL}/rest/V1/guest-carts/${state.cart.token}`
    ).catch(console.error)

    if (res && res.status === 200) {
      const data = await res.json()
      return data.id
    }
    return null
  }
)

const initialState = {
  id: null,
  token: null,
  items: [],
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartId: (state, action) => {
      state.id = action.payload
    },
    setCartToken: (state, action) => {
      state.token = action.payload
    },
    setCartItems: (state, action) => {
      state.items = action.payload
    },
    clearCart: (state) => {
      state.id = null
      state.token = null
      state.items = []
    }
  },
  extraReducers: builder => {
    builder.addCase(getCartId.fulfilled, (state, action) => {
      state.id = action.payload
    })
    builder.addCase(createGuestCart.fulfilled, (state, action) => {
      // Add user to the state array
      state.token = action.payload
    })
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      state.items = action.payload.items
    })
  },
})

export const { setCartToken, setCartItems, setCartId, clearCart } = cartSlice.actions

export default cartSlice.reducer
