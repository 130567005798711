class Product {
  static BUNDLE = 'BUNDLE'
  static CONFIGURABLE = 'CONFIGURABLE'
  static SIMPLE = 'SIMPLE'
  constructor(product, priceRuleItems = null) {
    this.id = product.id
    this.uid = product.uid
    this.stock_status = product.stock_status
    this.order_code = product.order_code
    this.country_of_manufacture = product.country_of_manufacture
    this.only_x_left_in_stock = product.only_x_left_in_stock
    this.sku = product.sku
    this.name = product.name
    this.__typename = product.__typename
    this.description = product.description
    this.short_description = product.short_description
    this.thumbnail = product.thumbnail
    this.meta_title = product.meta_title
    this.meta_keyword = product.meta_keyword
    this.meta_description = product.meta_description
    this.image = product.image
    this.media_gallery = product.media_gallery
    this.url_key = product.url_key
    this.special_price = product.special_price
    this.categories = product.categories
    this.url_rewrites = product.url_rewrites
    this.price_range = product.price_range
    this.product_brand = product.product_brand
    this.range = product.range
    this.room_type = product.room_type
    this.ages = product.ages
    this.variants = product.variants || []
    this.media_gallery_entries = product.media_gallery_entries || []
    this.configurable_options = product.configurable_options || []
    this.icons = product.icons
    this.video_title_1 = product.video_title_1
    this.video_image_1 = product.video_image_1
    this.video_embed_code_1 = product.video_embed_code_1
    this.video_title_2 = product.video_title_2
    this.video_image_2 = product.video_image_2
    this.video_embed_code_2 = product.video_embed_code_2
    this.seat_width = product.seat_width
    this.seat_height = product.seat_height
    this.overall_height = product.overall_height
    this.table_height = product.table_height
    this.finish = product.finish
    this.timber_type = product.timber_type
    this.delivery_information = product.delivery_information
    this.visibility_text = product.visibility_text
    this.dynamic_sku = product.dynamic_sku
    this.dynamic_price = product.dynamic_price
    this.dynamic_weight = product.dynamic_weight
    this.price_view = product.price_view
    this.ship_bundle_items = product.ship_bundle_items
    this.items = product.items
    this.colour = product.colour
    this.length = product.length
    this.width = product.width
    this.height = product.height
    this.material_label = product.material_label
    this.details_button_text = product.details_button_text
    this.details_button_link = product.details_button_link
    this.diameter = product.diameter
    this.product_quote = product.product_quote
    this.priceRuleItems = priceRuleItems
    this.related_products = product.related_products
  }

  get price() {
    if (this.priceRuleItems && this.priceRuleItems[this.id] !== undefined)
      return (
        this.priceRuleItems[this.id].price + this.priceRuleItems[this.id].discountOff
      )
    return this.calcPrice('regular_price')
  }

  get salePrice() {
    if (this.priceRuleItems && this.priceRuleItems[this.id] !== undefined)
      return this.priceRuleItems[this.id].price
    return this.calcPrice('final_price')
  }

  get discountOff() {
    if (this.price_range) return this.price_range.minimum_price['discount']?.amount_off
    if (this.variants && this.variants.length) {
      return this.variants.reduce((acc, v) => {
        if (!acc) return v.product.price_range.minimum_price['discount']?.amount_off
        return Math.min(acc, v.product.price_range.minimum_price['discount']?.amount_off)
      }, null)
    }
    return 0
  }

  get percentOff() {
    if (this.isBundle && this.price_range) return this.price_range.minimum_price['discount']?.percent_off
    return 0
  }

  get currency() {
    return this.calcCurrency('regular_price')
  }

  calcRangePrice(type = 'min') {
    if (this.isSimple) {
      return [this.salePrice, this.price]
    }

    if (this.isConfigurable) {
      return this.variants.reduce((m, variant) => {
        const vModel = new Product({ ...variant.product }, this.priceRuleItems)

        if (m[0] === 0 || (type === 'min' && vModel.salePrice < m[0]) || (type === 'max' && vModel.salePrice > m[0])) {
          return [vModel.salePrice, vModel.price]
        }

        return m
      }, [0, 0])
    }

    if (this.isBundle) {
      let result = [0, 0]
      for (const item of this.items) {
        const optionPrice = item.options.reduce((m, option) => {
          const pModel = new Product({ ...option.product }, this.priceRuleItems)
          const price = pModel[`${type}Price`] * option.quantity
          const fullPrice = pModel[`${type}FullPrice`] * option.quantity
          if (m[0] === 0 || (type === 'min' && price < m[0]) || (type === 'max' && price > m[0])) {
            return [price, fullPrice]
          }

          return m
        }, [0, 0])
        result[0] += optionPrice[0]
        result[1] += optionPrice[1]
      }
      result[0] = result[0] * (1 - this.percentOff / 100)
      return result
    }
  }

  get minPrice() {
    return this.calcRangePrice('min')[0]
  }

  get minFullPrice() {
    return this.calcRangePrice('min')[1]
  }

  get maxPrice() {
    return this.calcRangePrice('max')[0]
  }

  get maxFullPrice() {
    return this.calcRangePrice('max')[1]
  }

  get showQuote() {
    return this.product_quote === 1
  }

  get detailsImage() {
    const image = this.media_gallery_entries.find(entry => {
      if (!entry.disabled) {
        return !!entry.types && entry.types.includes('details_image')
      }
      return false
    })

    if (image) {
      return `${process.env.GATSBY_CMS_URL}/media/catalog/product${image.file}`
    }

    return null
  }

  get type() {
    switch (this.__typename) {
      case 'Magento_SimpleProduct':
        return Product.SIMPLE
      case 'Magento_BundleProduct':
        return Product.BUNDLE
      case 'Magento_ConfigurableProduct':
        return Product.CONFIGURABLE
      default:
        return null
    }
  }

  get isBundle() {
    return this.type === Product.BUNDLE
  }

  get isConfigurable() {
    return this.type === Product.CONFIGURABLE
  }

  get isSimple() {
    return this.type === Product.SIMPLE
  }

  calcCurrency(field) {
    if (this.price_range) return this.price_range.minimum_price[field].currency
    if (this.variants && this.variants.length) {
      return this.variants[0].product.price_range.minimum_price[field].currency
    }
    return 'AUD'
  }

  calcPrice(field) {
    if (this.price_range) return this.price_range.minimum_price[field].value
    if (this.variants && this.variants.length) {
      return this.variants.reduce((acc, v) => {
        if (!acc) return v.product.price_range.minimum_price[field].value
        return Math.min(acc, v.product.price_range.minimum_price[field].value)
      }, null)
    }
    return null
  }
}

module.exports = Product
