import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { graphql } from '../helpers/fetch'
import { setToken, setUser } from './auth'

const getWishlist = async (token, dispatch) => {
  const { data: res, errors } = await graphql(
    `${process.env.GATSBY_CMS_URL}/graphql`,
    `
      query {
        customer {
          wishlist {
            items_count
            sharing_code
            updated_at
            items {
              id
              qty
              description
              added_at
              product {
                sku
                name
              }
            }
            id
          }
        }
      }
    `,
    token
  )
  if (errors) {
    const authError =
      !!errors &&
      errors.some(e => e.extensions.category === 'graphql-authorization')
    if (authError) {
      dispatch(setToken(''))
      dispatch(setUser(null))
    }
    return null
  }
  return res.customer.wishlist
}

export const getWishlistItems = createAsyncThunk(
  'wishlist/getItems',
  async (arg, { getState, dispatch }) => {
    const state = getState()
    if (!state.auth.token)
      return {
        id: null,
        items: [],
      }

    const wishlist = await getWishlist(state.auth.token, dispatch)
    if (!wishlist) {
      return {
        id: null,
        items: [],
      }
    }
    return {
      id: wishlist.id,
      items: wishlist.items,
    }
  }
)

export const addItemToWishlist = createAsyncThunk(
  'wishlist/addItem',
  async ({ sku, options }, { getState, dispatch }) => {
    const state = getState()
    if (!state.auth.token) return false
    if (!state.wishlist.id) return false

    const {
      data: {
        addProductsToWishlist: { user_errors },
      },
      errors,
    } = await graphql(
      `${process.env.GATSBY_CMS_URL}/graphql`,
      `
        mutation {
          addProductsToWishlist(
            wishlistId: ${state.wishlist.id},
            wishlistItems: [
              { sku: "${sku}", quantity: 1${options.length > 0 ? `, selected_options: [${options.map(o => `"${o}"`).join(',')}]` : ''} }
            ]
          ) {
            wishlist {
              id
              items_count
            }
            user_errors {
              code
              message
            }
          }
        }
      `,
      state.auth.token
    )

    if (user_errors && user_errors.length > 0) {
      toast.error(user_errors.map(_ => _.message).join('. '), {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
      })
    }
    if (errors) {
      const authError =
        !!errors &&
        errors.some(e => e.extensions.category === 'graphql-authorization')
      if (authError) {
        dispatch(setToken(''))
        dispatch(setUser(null))
      }
      return false
    }
    dispatch(getWishlistItems())
    return true
  }
)

export const removeItemFromWishlist = createAsyncThunk(
  'wishlist/removeItem',
  async (id, { getState, dispatch }) => {
    const state = getState()
    if (!state.auth.token) return false
    if (!state.wishlist.id) return false

    const { data: res, errors } = await graphql(
      `${process.env.GATSBY_CMS_URL}/graphql`,
      `
        mutation {
          removeProductsFromWishlist(
            wishlistId: ${parseInt(state.wishlist.id, 10)},
            wishlistItemsIds: [
              ${id}
            ]
          ) {
            wishlist {
              id
              items_count
              sharing_code
              updated_at
              items {
                id
                qty
                description
                added_at
                product {
                  sku
                  name
                }
              }
            }
            user_errors {
              code
              message
            }
          }
        }
      `,
      state.auth.token
    )

    if (errors) {
      const authError =
        !!errors &&
        errors.some(e => e.extensions.category === 'graphql-authorization')
      if (authError) {
        dispatch(setToken(''))
        dispatch(setUser(null))
      }
      return false
    }
    dispatch(getWishlistItems())
    return true
  }
)

const initialState = {
  id: null,
  items: [],
}

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload
    },
    setItems: (state, action) => {
      state.items = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getWishlistItems.fulfilled, (state, action) => {
      // Add user to the state array
      state.id = action.payload.id
      state.items = action.payload.items
    })
    builder.addCase(addItemToWishlist.fulfilled, () => {})
    builder.addCase(removeItemFromWishlist.fulfilled, () => {})
  },
})

export const { setId, setItems } = wishlistSlice.actions

export default wishlistSlice.reducer
