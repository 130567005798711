import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { store } from '../store'
import { getCustomer, getCustomerCartToken } from '../store/auth'
import { createGuestCart, getCartId, getCartItems } from '../store/cart'
import { clearPriceRule, fetchPriceRuleItems } from '../store/priceRule'
import { clearQuote, getQuoteId, getQuoteItems } from '../store/quote'
import { getWishlistItems } from '../store/wishlist'

const persistor = persistStore(store)

const Root = ({ children }) => {
  const cartToken = useSelector(state => state.cart.token)
  const quoteToken = useSelector(state => state.quote.token)
  const authToken = useSelector(state => state.auth.token)
  const dispatch = useDispatch()

  useEffect(() => {
    if (authToken) {
      dispatch(getCustomer())
      dispatch(getCustomerCartToken())
      dispatch(getWishlistItems())
      return
    }
    if (!cartToken) dispatch(createGuestCart())
  }, [dispatch, cartToken, authToken])

  useEffect(() => {
    if (!authToken) {
      dispatch(clearQuote())
      dispatch(clearPriceRule())
    } else {
      dispatch(fetchPriceRuleItems())
    }
  }, [dispatch, authToken])

  useEffect(() => {
    // dispatch(getCartId())
    dispatch(getCartItems())
  }, [cartToken])

  useEffect(() => {
    dispatch(getQuoteItems())
  }, [quoteToken])

  return <>{children}</>
}

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Root>{element}</Root>
      </PersistGate>
    </Provider>
  )
}
