import { navigate } from "gatsby"

import './src/scss/style.scss'

export { wrapRootElement } from './src/wrappers/provider'

export const onClientEntry = () => {
  window.onload = () => {
    document.addEventListener('click', event => {
      if (event.target.tagName.toLowerCase() === 'a' && event.target.classList.contains('external-links')) {
        event.preventDefault()
        navigate(event.target.getAttribute('href'))
      }
    })
  }
}
