exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-static-blog-detail-js": () => import("./../../../src/pages/blog-static/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-static-blog-detail-js" */),
  "component---src-pages-blog-static-index-js": () => import("./../../../src/pages/blog-static/index.js" /* webpackChunkName: "component---src-pages-blog-static-index-js" */),
  "component---src-pages-content-builder-js": () => import("./../../../src/pages/content-builder.js" /* webpackChunkName: "component---src-pages-content-builder-js" */),
  "component---src-pages-dashboard-add-new-address-js": () => import("./../../../src/pages/dashboard/add-new-address.js" /* webpackChunkName: "component---src-pages-dashboard-add-new-address-js" */),
  "component---src-pages-dashboard-edit-personal-detail-js": () => import("./../../../src/pages/dashboard/edit-personal-detail.js" /* webpackChunkName: "component---src-pages-dashboard-edit-personal-detail-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-my-order-js": () => import("./../../../src/pages/dashboard/my-order.js" /* webpackChunkName: "component---src-pages-dashboard-my-order-js" */),
  "component---src-pages-dashboard-personal-detail-js": () => import("./../../../src/pages/dashboard/personal-detail.js" /* webpackChunkName: "component---src-pages-dashboard-personal-detail-js" */),
  "component---src-pages-index-static-js": () => import("./../../../src/pages/index-static.js" /* webpackChunkName: "component---src-pages-index-static-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-photos-js": () => import("./../../../src/pages/product/photos.js" /* webpackChunkName: "component---src-pages-product-photos-js" */),
  "component---src-pages-product-product-details-js": () => import("./../../../src/pages/product/product-details.js" /* webpackChunkName: "component---src-pages-product-product-details-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-sso-js": () => import("./../../../src/pages/sso.js" /* webpackChunkName: "component---src-pages-sso-js" */),
  "component---src-templates-magento-category-js": () => import("./../../../src/templates/magento/category.js" /* webpackChunkName: "component---src-templates-magento-category-js" */),
  "component---src-templates-magento-product-js": () => import("./../../../src/templates/magento/product.js" /* webpackChunkName: "component---src-templates-magento-product-js" */),
  "component---src-templates-magento-search-js": () => import("./../../../src/templates/magento/search.js" /* webpackChunkName: "component---src-templates-magento-search-js" */),
  "component---src-templates-magento-wishlist-js": () => import("./../../../src/templates/magento/wishlist.js" /* webpackChunkName: "component---src-templates-magento-wishlist-js" */),
  "component---src-templates-ss-content-builder-js": () => import("./../../../src/templates/ss/ContentBuilder.js" /* webpackChunkName: "component---src-templates-ss-content-builder-js" */),
  "component---src-templates-ss-home-page-js": () => import("./../../../src/templates/ss/HomePage.js" /* webpackChunkName: "component---src-templates-ss-home-page-js" */),
  "component---src-templates-ss-news-article-page-js": () => import("./../../../src/templates/ss/NewsArticlePage.js" /* webpackChunkName: "component---src-templates-ss-news-article-page-js" */),
  "component---src-templates-ss-news-category-page-js": () => import("./../../../src/templates/ss/NewsCategoryPage.js" /* webpackChunkName: "component---src-templates-ss-news-category-page-js" */)
}

